import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ajax from './ajax/ajax'
import './styles/common.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './filter/filter'
import './mixin/mixin'

Vue.config.productionTip = false;
Vue.use(ajax);
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
