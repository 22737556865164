import Vue from 'vue'

var filter = {
    imgFormat: function (src, isbg, addr) {
        let res = '', domain = addr || 'lvshuhualv.oss-cn-hangzhou.aliyuncs.com';
        if (!src) return;
        if (src.indexOf('http') != -1) {
            res = src.replace(src.split('//')[1].split('/')[0], domain)
        } else {
            res = `https://${domain}/` + src
        }
        if (isbg) {
            return 'background-image: url(' + res + ')'
        }
        return res
    }
}
for (var filteritem in filter) {
    Vue.filter(filteritem, filter[filteritem])
}