import Vue from 'vue'
import axios from 'axios'
Vue.mixin({
    methods: {
        //上传promise
        getUploadPromise(item, ossUploadData) {
            return new Promise(resolve => {
                let param = new FormData(),
                    fileName = ossUploadData.fileName + item.file.name // 创建form对象
                param.append('key', ossUploadData.path + fileName) //存储在oss的文件路径
                param.append('OSSAccessKeyId', ossUploadData.accessid) //accessKeyId
                param.append('policy', ossUploadData.policy) //policy
                param.append('Signature', ossUploadData.signature) //签名//如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                param.append('file', item.file)
                param.append('success_action_status', 200) //成功后返回的操作码
                axios.post(ossUploadData.host, param).then(res => {
                    resolve({
                        addr: ossUploadData.path + fileName,
                        info: item.info,
                    })
                })
            })
        },
        //base64 转file
        dataURLtoFile(dataurl, fileName) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            let blob = new Blob([u8arr], {
                type: mime
            })
            blob.lastModifiedDate = new Date()
            blob.name = fileName
            return blob
        }
    }
})
